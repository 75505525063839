import React, { useEffect, useRef, useState } from 'react';
import AuthLayout from '../../components/layout/AuthLayout';
import { useHistory } from 'react-router-dom';
import { apiSaveBasicDetail, getCityNames, getCountryNames, getDataDictionaryValues, getUserDetails } from '../../apis/apis';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DD_TYPE_INVESTOR_TYPE } from '../../../utils/constants/constants';
import { Autocomplete, CircularProgress, Popper, TextField } from '@mui/material';

const CommunicationDetails = () => {
    let history = useHistory();
    const { showSnackbar } = useSnackbar();

    const [data, setData] = useState({
        fullName: "",
        dobDoi: "",
        investorType: null,
        address: "",
        countryRid: "",
        cityRid: "",
        stateRid: "",
        countryName: "",
        cityName: "",
        countryCode: "",
    });
    const [investorTypeList, setInvestorTypeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [countryList, setCountryList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const listboxRef = useRef(null);
    const [isScrollingToBottom, setIsScrollingToBottom] = useState(false);

    const handle = (e) => {
        const { id, value } = e.target;
        const newData = { ...data };

        if (id === 'countryRid') {
            newData[id] = parseInt(value, 10);
        } else {
            newData[id] = value;
        }

        setData(newData);
    };

    const fetchUserData = async () => {
        const userDetails = await getUserDetails();

        if (userDetails.status) {
            const { address, fullName, dobDoi, investorType, countryCode, cityRid, countryRid, stateRid, countryName, cityName } = userDetails.data;

            setData({
                fullName: fullName,
                dobDoi: dobDoi?.split(' ')[0],
                investorType: investorType,
                address: address,
                cityRid: cityRid,
                countryRid: countryRid,
                stateRid: stateRid,
                countryName: countryName,
                cityName: cityName,
                countryCode: countryCode,
            });

            if (cityName) {
                getCities(countryRid, currentPage, cityName);
            }
        }
    };

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const invTypes = data.filter((item) =>
                    item.ddType === DD_TYPE_INVESTOR_TYPE
                );
                setInvestorTypeList(invTypes);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const getCountries = () => {
        const countryCode = data.countryCode || localStorage.getItem("dialCode");
        getCountryNames(countryCode)
            .then((res) => {
                setCountryList(res);
                setData({
                    ...data,
                    countryRid: res[0].countryRid,
                });
                if (data.cityName === null || data.cityName === "") {
                    getCities(res[0].countryRid);
                }
            })
            .catch((error) => {
                console.error('Error fetching countries:', error);
            });
    };

    const submit = (e) => {
        e.preventDefault();

        if (!data?.dobDoi || data.dobDoi.trim() === "") {
            showSnackbar("Please select DOB/DOI");
            return;
        }

        if (!data.investorType) {
            showSnackbar("Please select investor type");
            return;
        }

        if (!data.address?.trim()) {
            showSnackbar("Please enter an address");
            return;
        }

        if (!data.countryRid) {
            showSnackbar("Please select a country");
            return;
        }

        if (!data.cityRid) {
            showSnackbar("Please select a city");
            return;
        }

        setIsLoading(true);

        apiSaveBasicDetail(data)
            .then(() => {
                history.replace("/user/bank-details");
            })
            .catch((err) => {
                showSnackbar(err);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        setCurrentPage(1)
        fetchUserData();
        getDataDictionary();
        getCountries();
    }, []);

    const [hasMoreCity, setHasMoreCity] = useState("");
    const getCities = (countryRid = data.countryRid, page = 1, searchQuery = "") => {

        setLoading(true);
        setIsScrollingToBottom(true);
        getCityNames(countryRid, page, 20, searchQuery)
            .then((newCities) => {
                if (newCities && newCities.length > 0) {
                    setHasMoreCity("")
                    setCityList((prevList) => {
                        const uniqueCities = Array.from(
                            new Set([...prevList, ...newCities].map((city) => city.cityRid))
                        ).map((cityRid) => [...prevList, ...newCities].find((city) => city.cityRid === cityRid));
                        return uniqueCities;
                    });
                } else {
                    setHasMoreCity("true")
                }
            })
            .catch((e) => {
                console.error('Error fetching cities:', e);
            })
            .finally(() => {
                setLoading(false);
                setIsScrollingToBottom(false);
            });
    };

    useEffect(() => {
        if (!isScrollingToBottom && listboxRef.current && cityList.length > 0) {
            const newItemsHeight = listboxRef.current.scrollHeight;
            const offset = newItemsHeight * 0.5;
            listboxRef.current.scrollTop = newItemsHeight - offset;
        }
    }, [cityList]);

    useEffect(() => {
        if (isScrollingToBottom && listboxRef.current) {
            listboxRef.current.scrollTop = listboxRef.current.scrollHeight;
        }
    }, [isScrollingToBottom]);

    useEffect(() => {
        if (searchQuery.length >= 3 && data.countryRid) {
            getCityNames(data.countryRid, 1, 20, searchQuery)
                .then((data) => {
                    if (data) {
                        setCityList(data);
                        setTimeout(() => {
                            if (listboxRef.current) {
                                listboxRef.current.scrollTop = 0;  // Keep scroll at top after typing
                            }
                        }, 200);
                    }
                })
                .catch((e) => {
                    console.error('Error fetching filtered cities:', e);
                });
        }
    }, [data.countryRid, searchQuery]);

    const loadMoreCities = (e) => {
        const listBox = listboxRef.current;
        const isAtBottom = listBox.scrollHeight - listBox.scrollTop <= listBox.clientHeight + 10; // Slight margin for bottom
        if (isAtBottom && !loading) {
            const nextPage = currentPage + 1;
            setCurrentPage(nextPage);
            getCities(data.countryRid, currentPage + 1, searchQuery);
        }
    };

    useEffect(() => {
        // After loading new cities, adjust the scroll position 50% up from the bottom
        if (!isScrollingToBottom && listboxRef.current && cityList.length > 0) {
            const newItemsHeight = listboxRef.current.scrollHeight;
            const offset = newItemsHeight * 0.5;
            listboxRef.current.scrollTop = newItemsHeight - offset;
        }
    }, [cityList]);

    return (
        <AuthLayout title="Other Details">
            <form onSubmit={submit}>
                <div className="form-floating mb-4 mt-2">
                    <input
                        className="form-control"
                        placeholder="DOB"
                        name="dobDoi"
                        type="date"
                        value={data.dobDoi || ""}
                        id="dobDoi"
                        onChange={handle}
                    />
                    <label htmlFor="dobDoi">DOB</label>
                </div>

                <div className="form-floating mb-4">
                    <select
                        className="form-select"
                        id="investorType"
                        name="investorType"
                        onChange={handle}
                        value={data.investorType}
                        aria-label="Investor Type"
                    >
                        <option value={null}></option>
                        {investorTypeList?.map((item) => (
                            <option key={item.ddIndex} value={item.ddIndex}>
                                {item.ddValue}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="investorType">Investor Type</label>
                </div>

                <div className="form-floating mb-4">
                    <input
                        className="form-control"
                        placeholder="Address"
                        name="address"
                        value={data.address}
                        id="address"
                        onChange={handle}
                    />
                    <label htmlFor="address">Address</label>
                </div>

                <div className="form-floating mb-4">
                    {countryList?.length > 0 ? (
                        <select
                            className="form-select"
                            id="countryRid"
                            name="countryRid"
                            onChange={handle}
                            value={data.countryRid || ''}
                            aria-label="Select Country"
                        >
                            <option value={null}></option>
                            {countryList?.map((country) => (
                                <option key={country.countryRid} value={country.countryRid}>
                                    {country.name}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <div className="form-control"></div>
                    )}
                    <label htmlFor="countryRid">Country</label>
                </div>

                <Autocomplete
                    options={cityList}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) => option.cityRid === value?.cityRid}
                    value={cityList.find((city) => city.cityRid === data.cityRid) || null}
                    onInputChange={(event, value) => {
                        setSearchQuery(value);
                        if (value.length === 0) {
                            if (listboxRef.current) {
                                listboxRef.current.scrollTop = 0;
                            }
                            setHasMoreCity("")
                            setCityList([]);
                            setCurrentPage(1)
                            getCities(data.countryRid, 1, value);
                        } else if (value.length < 3) {
                            if (listboxRef.current) {
                                listboxRef.current.scrollTop = 0;
                            }
                        } else {
                            setCurrentPage(1)
                        }
                    }}

                    onChange={(event, value) => {
                        setData({
                            ...data,
                            cityRid: value ? value.cityRid : "",
                            stateRid: value ? value.stateRid : "",
                        });
                    }}
                    renderOption={(props, option) => (
                        <li {...props} key={option.cityRid}>
                            {option.name} {option.stateName && ` [${option.stateName}]`}
                        </li>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="City"
                            placeholder="Search your city"
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    disablePortal
                    fullWidth
                    PopperComponent={(props) => (
                        <Popper
                            {...props}
                            style={{
                                ...props.style,
                                zIndex: 1.5,
                            }}
                        />
                    )}
                    ListboxComponent={(props) => (
                        <ul
                            {...props}
                            ref={listboxRef}
                            onScroll={hasMoreCity === "" ? loadMoreCities : undefined}
                            style={{
                                overflowY: 'auto',
                                maxHeight: 280,
                                zIndex: 2,
                            }}
                        />
                    )}
                />

                <div className="d-flex mt-4 mb-2">
                    <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
                        Save & Continue
                    </button>
                </div>
            </form>
        </AuthLayout>
    );
};

export default CommunicationDetails;